import Chart from "../../components/chart/Chart";
import FeaturedInfo from "../../components/featuredInfo/FeaturedInfo";
import "./home.css";
import WidgetSm from "../../components/widgetSm/WidgetSm";
import WidgetLg from "../../components/widgetLg/WidgetLg";
import { useEffect, useState } from "react";
import axios from "axios";
import { useMemo } from "react";
const axiosInstance = axios.create({
  baseURL:process.env.REACT_APP_API_URL
});


export default function Home() {
  const MONTHS = useMemo(()=>[
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ],[]
  );

  const [userStats, setUserStats] = useState([]);

  useEffect(()=> {
    const getStats = async ()=>{
    try {
      const res = await axiosInstance.get("/users/stats", {
        headers: {
          token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
        },
      });
      const statsList = res.data.sort(function(a,b){
        return a._id - b._id;
      });
      statsList.map((item )=> 
      setUserStats(prev =>
        [...prev,{name:MONTHS[item._id-1], "New User":item.total},
      ])
      );
    } catch (err) {
      console.log(err);
    }
  };
    getStats();
  }, [MONTHS]);

  return (
    <div className="home">
      <FeaturedInfo />
      <Chart data={userStats} title="User Analytics" grid dataKey="New User"/>
      <div className="homeWidgets">
        <WidgetSm/>
        <WidgetLg/>
      </div>
    </div>
  );
}
